import logo from './a-logo.svg';
import './App.css';


function App() {

  return (
    <div style={{
      backgroundColor: 'rgb(7 30 61)',
      textAlign: 'center'
    }}>
      <header style={{
        backgroundColor: 'white',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'calc(10px + 2vmin)',
      }}>
          <img src={logo} style={{
            height: '40vmin'
          }}  alt="logo" />
        <p>
          Private Cardano Stake Pool
        </p>
        <p style={{fontSize: '25px'}}>
          <i>inquire@adalido.io</i>
        </p>
      </header>
    </div>
  );
}

export default App;
